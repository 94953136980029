
((document, window, history) => {


	function playSound() {
		const soundIcon = document.querySelector('.sound-icon');
		const audio = new Audio('/app/themes/Farlo/dist/assets/media/drstrangelove.mp3');
		let isPlaying = false;

		soundIcon.addEventListener('click', function() {
			if (isPlaying) {
				audio.pause();
			} else {
				audio.play();
			}

			isPlaying = !isPlaying;
			soundIcon.classList.toggle('sound-icon--active');
		});
	}

	function adjustBookNowButton() {
		const masthead = document.querySelector('.site-masthead');
		const bookNowButton = document.querySelector('.site-book-now-button');
		
		if (!masthead || !bookNowButton) {
			console.error('Elements not found!');
			return;
		}
	
		function updateButtonPosition() {
			const mastheadRect = masthead.getBoundingClientRect();
			const windowHeight = window.innerHeight;
	
			if (window.innerWidth < 980) {
				if (mastheadRect.bottom > 0) {
					bookNowButton.style.transform = 'translateX(-50%) translateY(100%)';
				} else {
					bookNowButton.style.transform = 'translateX(-50%)';
				}
			} else {
				bookNowButton.style.transform = 'none';
			}
		}
	
		// Initial check
		updateButtonPosition();
	
		// Listen for window resize events
		window.addEventListener('resize', updateButtonPosition);
	
		// Listen for scroll events
		window.addEventListener('scroll', updateButtonPosition);
	}

	// default cookie compliance script
	function cookieCompliance() {
		if (document.querySelector('.farlo-ui-biscuit-banner__button')) {
			document.querySelector('.farlo-ui-biscuit-banner__button').addEventListener('click', (e) => {
				e.preventDefault();

				e.target.closest('.farlo-ui-biscuit-banner').classList.add('farlo-ui-biscuit-banner--hide');

				window._farlo.storage.set('cookieCompliance', 'true', 'cookie');
			});
		}

		if (document.querySelector('.farlo-ui-biscuit-banner__button--close')) {
			document.querySelector('.farlo-ui-biscuit-banner__button--close').addEventListener('click', (e) => {
				e.preventDefault();

				e.target.closest('.farlo-ui-biscuit-banner').classList.add('farlo-ui-biscuit-banner--hide');
			});
		}
	}

	// default navicon
	function navicon() {
		console.log('running');
		document.querySelector('.site-navigation__navicon').addEventListener('click', (e) => {
			e.preventDefault();
			e.currentTarget.classList.toggle('site-navigation__navicon--active'); document.querySelector('.site-navigation').classList.toggle('site-navigation--active');
			document.body.classList.toggle('site-navigation--open');
		});
	}
	// default navigation subnav
	function navigation(submenu) {
		if (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0)) {
			window._farlo.helpers.getSiblings(submenu)[ 0 ].addEventListener('click', (e) => {
				if (!e.target.classList.contains('site-navigation__menu-item--active')) {
					e.preventDefault();

					e.currentTarget.classList.add('site-navigation__menu-item--active');
					submenu.classList.add('site-navigation__sub-menu--active');

					for (let i = 0; i < window._farlo.helpers.getSiblings(e.currentTarget.parentNode).length; i += 1) {
						if (window._farlo.helpers.getSiblings(e.currentTarget.parentNode)[ i ].children[ 0 ] && window._farlo.helpers.getSiblings(e.currentTarget.parentNode)[ i ].children[ 0 ].classList.contains('site-navigation__menu-item--active')) {
							window._farlo.helpers.getSiblings(e.currentTarget.parentNode)[ i ].children[ 0 ].classList.remove('site-navigation__menu-item--active');
						}

						if (window._farlo.helpers.getSiblings(e.currentTarget.parentNode)[ i ].children[ 1 ] && window._farlo.helpers.getSiblings(e.currentTarget.parentNode)[ i ].children[ 1 ].classList.contains('site-navigation__sub-menu--active')) {
							window._farlo.helpers.getSiblings(e.currentTarget.parentNode)[ i ].children[ 1 ].classList.remove('site-navigation__sub-menu--active');
						}
					}
				} else {
					return true;
				}
			});
		} else {
			submenu.parentNode.addEventListener('mouseover', () => {
				if (submenu.parentNode.querySelector('.site-navigation__sub-menu--active')) {
					submenu.parentNode.querySelectorAll('.site-navigation__sub-menu--active').forEach((_sel) => {
						_sel.classList.remove('site-navigation__sub-menu--active');
						_sel.parentNode.classList.remove('site-navigation__menu-item--active');
					});
				}
				submenu.classList.add('site-navigation__sub-menu--active');
				submenu.parentNode.classList.add('site-navigation__menu-item--active');
			});

			submenu.parentNode.addEventListener('mouseout', () => {
				submenu.classList.remove('site-navigation__sub-menu--active');
				submenu.parentNode.classList.remove('site-navigation__menu-item--active');
			});
		}
	}

	function farloUiMcSignup(_sel) {
		let farloUiSignupForm = null;

		if (farloUiSignupForm) {
			farloUiSignupForm.toggleForm();
		} else {
			farloUiSignupForm = new FarloUiMc(_sel.id);
		}
	}

	function typed(_sel) {
		const type = new Typed(_sel, {
			strings: [_sel.parentNode.querySelector('.site-typed__text').innerText],
			typeSpeed: 30,
			startDelay: 1000,
			showCursor: false
		});
	}

	function typedBottom(_sel) {
		const type = new Typed(_sel, {
			strings: [_sel.parentNode.querySelector('.typed-bottom__text').innerText],
			typeSpeed: 30,
			startDelay: 1000,
			showCursor: false
		});
	}

	function typedDelayed(_sel) {
		const type = new Typed(_sel, {
			strings: [_sel.parentNode.querySelector('.site-typed-delayed__text').innerHTML],
			typeSpeed: 30,
			startDelay: 2700,
			showCursor: false
		});
	}

	function runOnScrollToBottom(callback) {
		let ticking = false;
		let hasReachedBottom = false; // Flag variable

		function handleScroll() {
			if (ticking) {
				return;
			}

			ticking = true;

			const scrollY = window.scrollY || window.pageYOffset;
			const windowHeight = window.innerHeight;
			const documentHeight = document.documentElement.scrollHeight;

			if (scrollY + windowHeight >= documentHeight && !hasReachedBottom) {
				hasReachedBottom = true; // Set the flag to true
				callback();
			}

			ticking = false;
		}

		window.addEventListener('scroll', handleScroll);
	}

	function farloUiLightbox(elem, div) {
		function closeLightbox() {
			document.body.classList.remove('body--farlo-ui-lightbox-open');
			div.classList.remove('site-farlo-ui-lightbox--active');
			if (div.querySelector('.site-farlo-ui-lightbox__inner-content.dynamic')) {
				div.querySelector('.site-farlo-ui-lightbox__inner-content').classList.remove('dynamic');
			}
			div.querySelector('.site-farlo-ui-lightbox__inner-content').innerHTML = '';
		}

		if (elem.querySelector('.wp-block-image')) {
			elem.querySelectorAll('.wp-block-image').forEach((_sel) => {
				_sel.addEventListener('click', (e) => {
					const clonedEmbed = e.currentTarget.cloneNode(true);
					document.body.classList.add('body--farlo-ui-lightbox-open');
					div.querySelector('.site-farlo-ui-lightbox__inner-content').innerHTML = '';
					clonedEmbed.removeAttribute('style');
					div.querySelector('.site-farlo-ui-lightbox__inner-content').appendChild(clonedEmbed);
					div.classList.add('site-farlo-ui-lightbox--active');
				});
			});
		}

		if (elem.classList.contains('site-book-now-button')) {
			elem.querySelector('.button--book').addEventListener('click', function(e) {
				e.preventDefault();
				e.stopPropagation();
				const cloneButtons = document.querySelector('.site-book-buttons').cloneNode(true);
				document.body.classList.add('body--farlo-ui-lightbox-open');
				div.querySelector('.site-farlo-ui-lightbox__inner-content').innerHTML = '';
				cloneButtons.removeAttribute('style');
				div.querySelector('.site-farlo-ui-lightbox__inner-content').appendChild(cloneButtons);
				div.classList.add('site-farlo-ui-lightbox--active');
			});
		}

		if ((elem.nextElementSibling && elem.nextElementSibling.value) || (elem.querySelector('.site-farlo-ui-lightbox__html') && elem.querySelector('.site-farlo-ui-lightbox__html').value)) {
			elem.addEventListener('click', function(e) {
				e.preventDefault();
				e.stopPropagation();
				document.body.classList.add('body--farlo-ui-lightbox-open');
				div.querySelector('.site-farlo-ui-lightbox__inner-content').classList.add('dynamic');

				if ((e.currentTarget.nextElementSibling && e.currentTarget.nextElementSibling.value)) {
					div.querySelector('.site-farlo-ui-lightbox__inner-content').innerHTML = e.currentTarget.nextElementSibling.value;
				} else if (e.currentTarget.querySelector('.site-farlo-ui-lightbox__html') && e.currentTarget.querySelector('.site-farlo-ui-lightbox__html').value) {
					div.querySelector('.site-farlo-ui-lightbox__inner-content').innerHTML = e.currentTarget.querySelector('.site-farlo-ui-lightbox__html').value;
				}
				div.classList.add('site-farlo-ui-lightbox--active');
			});
		}

		if (elem.querySelector('a[href*="youtu.be"]')) {
			elem.querySelector('a[href*="youtu.be"]').addEventListener('click', function(e) {
				e.preventDefault();
				document.body.classList.add('body--farlo-ui-lightbox-open');
				div.querySelector('.site-farlo-ui-lightbox__inner-content').innerHTML = '<iframe class="site-farlo-ui-lightbox__inner-content-iframe" src="https://www.youtube-nocookie.com/embed/' + e.target.href.match(youTubeRegex)[ 1 ] + '" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';
				div.classList.add('site-farlo-ui-lightbox--active');
			});
		}

		if (div.querySelector('.site-farlo-ui-lightbox__button-close')) {
			div.querySelector('.site-farlo-ui-lightbox__button-close').addEventListener('click', function(e) {
				e.preventDefault();
				closeLightbox();
			});
		}

		// Close lightbox on ESC key press
		document.addEventListener('keydown', function(e) {
			if (e.key === 'Escape') {
				closeLightbox();
			}
		});

		// Close lightbox on click outside
		document.addEventListener('click', function(e) {
			if (e.target.classList.contains('site-farlo-ui-lightbox__inner')) {
				closeLightbox();
			}
		});
	}

	runOnScrollToBottom(function() {
		document.querySelectorAll('.typed-bottom__empty').forEach((_sel) => {
			typedBottom(_sel);
		});
	});


	document.addEventListener('DOMContentLoaded', () => {
		
		if (document.querySelector('.site-navigation__navicon')) {
			navicon();
		}

		if (document.querySelector('.site-masthead')){
			adjustBookNowButton();
		}

		if (document.querySelector('.sound-icon')) {
			playSound();
		}

		if (document.querySelector('.site-farlo-ui-biscuit-banner')) {
			cookieCompliance();
		}

		document.querySelectorAll('.site-typed__empty').forEach((_sel) => {
			typed(_sel);
		});

		document.querySelectorAll('.site-typed-delayed__empty').forEach((_sel) => {
			typedDelayed(_sel);
		});
		const lightboxEmbed = document.querySelectorAll('.site-video-embed-group, .site-farlo-ui-lightbox__toggle, .wp-block-gallery, .site-book-now-button');
		let div;
		let divInner;
		let divInnerContent;
		let divClose;

		if (lightboxEmbed.length > 0) {
			div = document.createElement('div');
			divInner = document.createElement('div');
			divInnerContent = document.createElement('div');
			divClose = document.createElement('button');
			div.className = 'site-farlo-ui-lightbox';
			divInner.className = 'site-farlo-ui-lightbox__inner';
			divInnerContent.className = 'site-farlo-ui-lightbox__inner-content';
			divClose.className = 'site-farlo-ui-lightbox__button-close';

			document.body.appendChild(div);
			document.querySelector('.site-farlo-ui-lightbox').appendChild(divInner);
			document.querySelector('.site-farlo-ui-lightbox__inner').appendChild(divInnerContent);
			document.querySelector('.site-farlo-ui-lightbox__inner').appendChild(divClose);

			lightboxEmbed.forEach(function(elem) {
				farloUiLightbox(elem, div);
			});
		}

		if (document.querySelector('.site-farlo-ui-crm')) {
			document.querySelectorAll('[farlo-ui="crm"]').forEach((_sel) => {
				farloUiMcSignup(_sel);
			});

			document.querySelectorAll('.site-farlo-ui-crm__toggle').forEach((_sel) => {
				_sel.addEventListener('click', (e) => {
					e.preventDefault();
					farloUiMcSignup(document.querySelector('#' + e.currentTarget.dataset.crmButtonFor));
				});
			});
		}
	});

	window.addEventListener('load', () => {
		if (document.querySelector('.site-farlo-ui-revolver-carousel')) {
			document.querySelectorAll('[farlo-ui="carousel"]').forEach((_sel) => {
				_sel.farloUiRevolverCarousel();
			});
		}
	});

	if (typeof navigator !== 'undefined') {
		navigator.serviceWorker.getRegistrations().then(function(registrations) {
			for (const registration of registrations) {
				registration.unregister();
			}
		});
	}
})(document, window, history);
